.admin-footer {
  padding: 15px;
}
a {
  text-decoration: none;
}
.copyrights .webnexs {
  color: #4895d1;
}
.copyrights .lable {
  color: #000000;
}
.copyrights1Link {
  color: #000000;
}

@media (max-width: 991px) {
  .admin-footer,
  body.sidebar-main .admin-footer {
    text-align: center;
    margin-left: 0;
    width: 100%;
  }
}
