.userWidth>th:nth-child(3) {
  width: 260px;
}

.total_user_subcriber {
  font-size: 11px;
}

.userDesignBox {
  width: 160px !important;
}

.userProfileImageSize {
  width: 50px;
  height: 50px;
}

.rightUserTitle {
  width: 120px;
}

.userEmailBox {
  width: 200px !important;
}

.userNameProfile {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.userDateText {
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
}

.countHead {
  font-size: 18px;
  font-weight: 400;
}

.countDes {
  font-size: 11px;
  font-weight: 300;
}

.alluser_card_number {
  height: 25vh;
}

.rotate-load {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}