.sideBarBrand {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.sideMenu {
  padding: 10px 15px !important;
  cursor: pointer;
  color: inherit;
}

.sideMenu.active {
  background-color: rgb(45 48 56 / 8%);
  border-radius: 8px;
  color: inherit;
}

.headingActive {
  background-color: rgb(45 48 56 / 8%) !important;
  border-radius: 8px;
}

.sideMenu>div>svg {
  width: 20px;
  height: 20px;
}

.sideMenu.active svg,
.sideMenu.active span {
  color: #345afa;
}

.sideMenu:active,
.sideMenu:focus,
.sideMenu:hover {
  color: inherit !important;
  text-decoration: none;
}

/* .adminSideBarLeft [aria-expanded='true'] {
  background-color: rgb(45 48 56 / 8%);
  border-radius: 8px;
} */

.sideBarHeading {
  font-size: 16px;
  font-weight: 600;
  color: #192528;
}

.visitSite {
  background-color: #0d6efd;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
}

.visitSite>a {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}