.upload {
    margin: 80px auto;
}

.thumbnails-images-mobilesetting {
    width: 50px !important;
    height: 75px !important;
}
.thumbnails-video-mobilesetting {
    width: auto !important;
    height: 75px !important;
}


.nodata-available{
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: le;
    left: 100vh;
    border-bottom: 0;
    font-size: 16px !important;
}