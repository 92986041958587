
.currentActive {
  background: #d3ffd3;
  color: green;
  padding: 5px !important;
  border-radius: 5px;
  font-weight: 600;
}
.inActive {
  background: #ffd9d9;
  color: red;
  padding: 5px !important;
  border-radius: 5px;
  font-weight: 600;
}
.dropdown-toggle::after {
  display: none !important;
}
