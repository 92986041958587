/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.user-webnexs .card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.user-webnexs span.small.pt-1.fw-bold {
  color: black;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.dashboard .nav-link {
  border-radius: 5px;
  border-bottom: 1px solid #e9e6e6;
  font-weight: 500;
  color: #000000;
  background-color: #e1e1e1;
}

.dashboard .nav-link.active {
  background: rgb(255 255 255 / 20%);
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.upload-video {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.iq-card {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
}


.menuImage {
  width: 50px;
  height: 50px;
}

.menuName {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.menuDate {
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
}

/* .menuTable>th:nth-child(1) {
  width: 80%;
} */

.menuTable th:nth-child(2) {
  width: 50%;
}

.menuTable th:nth-child(3) {
  width: 10%;
}

.menuTable th:nth-child(4) {
  width: 10%;
}
.menuIconImageLoad{
  width: 180px;
  height: 80px;
}