.eyepassworduser {
    position: absolute;
    padding: 35px;
    right: 0%;
    font-size: 18px !important;
    top: 80%;
}

.subscriptionName{
        font-size: 13px;
        font-weight: bold;
        line-height: 30px;
}
.subscriptionSub{
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 10px;
}
