.episode_video_data label small {
  font-size: 12px;
  font-weight: 100;
}

.addepisode .css-16r9pk3 {
  max-width: 1200px !important;
}
.addepisode .css-14yr603-MuiStepContent-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: none !important;
}
.addepisode .css-ahj2mt-MuiTypography-root {
  /* margin: 20px 10px 10px 0px !important; */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.image_error_msg {
  color: red;
}
.addepisode .css-m5vj9m-MuiStepper-root {
  display: block !important;
}

.addepisode .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600 !important;
}
.addepisode .small,
small {
  font-size: 12px !important;
}

.addepisode .css-1mz1l2x-MuiStepContent-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.addepisode .css-1bw0nnu-MuiStep-root {
  padding-left: 0px !important;
}

.addepisode .css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: none;
  border-top-style: none !important;
  border-top-width: 1px;
}
.addepisode .css-1qm1lh {
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
}
.addepisode .css-ascpo7-MuiStepLabel-root {
  flex-direction: column;
}

.addepisode #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addepisode .file-drag {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}
.addepisode #input-file-upload {
  justify-content: center;
  align-items: center;
  text-align-last: center;
}

.addepisode .MuiStep-horizontal {
  width: 100%;
}
.css-8t49rw-MuiStepConnector-line {
  display: none !important;
  border-color: #bdbdbd;
  border-left-style: solid;
  border-left-width: 1px;
  min-height: 24px;
}