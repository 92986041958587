.video_schedule_media_tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #000000 !important;
  border-bottom: 2px #0d6efd solid;
  border-radius: 0 !important;
}
.video_schedule_media_tab .nav-pills .nav-link {
  color: #000000 !important;
}
/* .video_schedule_media_tab media-outlet {
  width: 180px !important;
} */
/* .video_schedule_media_tab media-player {
  overflow: hidden;
} */

.video_schedule_media_tab .overflow-media-outlet-page {
  overflow: scroll;
  max-height: 200px;
  overflow-x: hidden;
}
