.newdraganddrop_schedule video {
  width: 200px;
}

.newdraganddrop_schedule .scroll_video_draganddrop {
  overflow: scroll;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.newdraganddrop_schedule .videos .imgae {
  width: 65px !important;
  height: 65px !important;
  object-fit: contain;
}
.newdraganddrop_schedule .board .imgae {
  width: 65px !important;
  height: 65px !important;
  object-fit: contain;
}

.newdraganddrop_schedule .next_previous button {
  font-size: 22px;
  padding: 10px;
}
.newdraganddrop_schedule .next_previous button.next-11 {
  border-left: 1px solid rgb(229, 229, 234);
}
.newdraganddrop_schedule .react-select__indicator svg {
  display: none !important;
}
.newdraganddrop_schedule
  .react-select-container.form-select.css-b62m3t-container {
  border: 1px solid rgb(229, 229, 234) !important;
}

.newdraganddrop_schedule .DatePicker {
  height: 52px;
}
/* .newdraganddrop_schedule i.fa.fa-calendar-o {
  position: absolute;
  z-index: 1;
  right: 37%;
  top: 21%;
} */
/* .newdraganddrop_schedule .react-datepicker-popper {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(90%, 30%, 0px) !important;
} */

/* Style for the dropdown container */
.editdropdowntest {
  position: relative;
  display: inline-block;
  z-index: 9;
}

/* Style for the dropdown button */
.editdropdowntest-button {
  /* background-color:  rgb(240, 240, 240) ; */
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown menu */
.editdropdowntest-menu {
  display: none;
  left: 25px;
  top: 10px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show the dropdown menu when the button is clicked */
.editdropdowntest:hover .editdropdowntest-menu {
  display: block;
}

/* Style for dropdown menu items */
.editdropdowntest-menu a {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
}

/* Highlight dropdown menu items on hover */
.editdropdowntest-menu a:hover {
  background-color: #f1f1f1;
  padding: 5px;
}

/* Style for the dropdown container */
.editdropdownpage {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown button */
.editdropdownpage-button {
  /* background-color:  rgb(240, 240, 240) ; */
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown menu */
.editdropdownpage-menu {
  display: none;
  left: -93px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show the dropdown menu when the button is clicked */
.editdropdownpage:hover .editdropdownpage-menu {
  display: block;
}

/* Style for dropdown menu items */
.editdropdownpage-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Highlight dropdown menu items on hover */
.editdropdownpage-menu a:hover {
  background-color: #f1f1f1;
}

.commonActionPaddingcommonActionPadding {
  padding: 5px;
}

.commonActionPaddingcommonActionPadding:hover {
  background-color: #f1f1f1;
}
