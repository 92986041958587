.thumbnails-images-theme-setting {
    width: 200px;
    height: 36px;
    object-fit: contain;
    margin: 10px 0px;
}

.colorPicker {
    height: 60px;
    -webkit-appearance: none;
    border-radius: 6px;
    overflow: hidden;
}

.colorPicker:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.colorText {
    font-size: 12px !important;
    font-weight: 600;
    margin: 0 !important;
}

.pickerWidth {
    width: 100px;
}

.showCode {
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}

.showCode:focus {
    box-shadow: 0px 0px 1px 1px #ccc;
}

.showCode:focus-visible {
    box-shadow: 0px 0px 1px 1px #ccc;
    outline: none;
}