.addvideo .css-16r9pk3 {
  max-width: 1200px !important;
}

.addvideo .css-14yr603-MuiStepContent-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: none !important;
}

.addvideo .css-ahj2mt-MuiTypography-root {
  margin: 20px 10px 10px 0px !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.image_error_msg {
  color: red;
}

.video_source .css-m5vj9m-MuiStepper-root {
  display: block !important;
}

.addvideo .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600 !important;
}

.addvideo .small,
small {
  font-size: 12px !important;
}

.addvideo .css-1mz1l2x-MuiStepContent-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.addvideo .css-1bw0nnu-MuiStep-root {
  padding-left: 0px !important;
}

.video .css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: none !important;
  border-top-width: 1px;
}

.addvideo .css-1qm1lh {
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
}

.addvideo .css-ascpo7-MuiStepLabel-root {
  flex-direction: column;
}

.addvideo #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addvideo .file-drag {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.addvideo #input-file-upload {
  justify-content: center;
  align-items: center;
  text-align-last: center;
}

.searchWrapper {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.videoupload {
  margin-bottom: 12px;
}

.formcreatable .css-13cymwt-control {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  /* -webkit-flex-wrap: wrap; */
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: transparent !important;
  border: none !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(250, 250, 250, 1) !important;
  box-sizing: border-box;
}

/* .css-t3ipsp-control:hover {
  background-color: rgba(250, 250, 250, 1) !important;
} */

.draganddrop {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  cursor: pointer;
}

.file-draganddrop {
  height: 100px;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  text-align: center;
  border-color: #cbd5e1;
  background-color: transparent;
}










.video-overall-progress-li {
  margin: auto;
}

.upload-progress-list {
  display: flex;
  flex-wrap: wrap;
}

.upload-progress-item {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  width: 18%;
  /* Adjust the width to fit 3 items per row */
  margin-right: 15px;
}

.file-info {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.file-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-icon {
  width: 30px;
  height: 30px;
}

.file-name {
  font-weight: bold;
  font-size: 14px;
}

.file-size {
  color: #888;
  font-size: 12px;
}

.file-uploadSpeed {
  color: #888;
  font-size: 12px;
}

.progress-bar-container {
  /* margin-top: 10px; */
  text-align: center;
}

/* .file-progress-bar {
  width: 100%;
  padding: 0px 5px 0px 5px;
  height: 6px;
  border-radius: 5px;
  background-color: #e0e0e0;
  appearance: none;
} */

/* .file-progress-bar::-webkit-progress-value {
  background-color: #007bff;
  border-radius: 5px;
} */

.progress-percentage {
  font-size: 14px;
  color: #555;
}

.cancel-icon {
  width: 10px;
  height: 15px;
  cursor: pointer;
  margin-top: 5px;
}

.uploaded-files-section,
.failed-files-section {
  margin-top: 20px;
}

.proceed-button {
  margin-top: 20px;
}

.video-overall-progress-li img.cancel-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 2%;
}

.file-progress-bar {
  padding: 0px 5px;
  height: 6px;
  border-radius: 10px;
  /* Adding radius to the progress bar */
  background-color: #fff;
  /* White background inside the progress bar */
  appearance: none;
  /* width: 100%; */
}

.file-progress-bar::-webkit-progress-bar {
  background-color: #fff;
  /* White background inside the progress bar */
  border-radius: 10px;
  /* Rounded corners for the progress bar */
}

.file-progress-bar::-webkit-progress-value {
  background-color: #007bff;
  /* Blue color for the progress */
  border-radius: 10px;
  /* Rounded corners for the progress value */
}