.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-fieldtranslate {
  padding: 10px 70px 10px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.clear-icon {
  right: 10px;
  /* Adjust based on size of icons */
}

.save-icon {
  width: 23px;
  height: 23px;
}

.actionTable {
  top: 18px;
  right: 15px;
}

.arrowButton,
.mapButton {
  cursor: pointer;
  width: 45px;
  height: 35px;
  background-color: transparent;
}

.arrowButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.arrowButton:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.arrowButton:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.customPaginationBorder {
  border-style: solid;
  border-width: 1px;
}

.languageTable table {
  border-collapse: collapse;
  border-width: 2px;
  border-style: solid;
}

/* .languageTable {
  width: 1000px;
  overflow: hidden;
}

.languageTable>table {
  width: 1000px;
  overflow: scroll;
}

.languageTable thead>tr>th:nth-child(1) {
  width: 20%;
}

.languageTable thead>tr>th {
  width: 200px;
}

.languageTable tbody>tr>td {
  width: 200px;
} */