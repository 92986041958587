.searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
    position: absolute;
    top: 74px;
    right: 35px;
    width: 30px;
    height: 33px;
    z-index: 1;
}

.searchPageIconFasearch {
    position: absolute;
    top: 74px;
    right: 35px;
    width: 30px;
    height: 33px;
    z-index: 1;
}



@media (min-width:320px) {

    .searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
        position: absolute;
        top: 112px;
        right: 15px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }

    .searchPageIconFasearch {
        position: absolute;
        top: 60px;
        right: 8px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }
}

@media (min-width:768px) {
    .searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
        position: absolute;
        top: 73px;
        right: 20px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }

    .searchPageIconFasearch {
        position: absolute;
        top: 103px;
        right: 27px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }
}

@media (min-width:961px) {
    .searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
        position: absolute;
        top: 73px;
        right: 22px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }

    .searchPageIconFasearch {
        position: absolute;
        top: 103px;
        right: 40px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }
}

@media (min-width:1025px) {
    .searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
        position: absolute;
        top: 73px;
        right: 34px;
        width: 25px;
        height: 38px;
        z-index: 1;
    }

    .searchPageIconFasearch {
        position: absolute;
        top: 100px;
        right: 40px;
        width: 28px;
        height: 50px;
        z-index: 1;
    }
}