/* signup start */


.signup input.input {
  font-size: 18px;
  height: 50px;
}

.signup h1 {
  font-style: normal;
 font-family: 'museo_sans700';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.box-signup {
  background: #ffff;
  width: 400px;
  border-radius: 6px;
}

.signup button.button{
  background: #111727;
  color: #ffff !important;
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.signup button.button :hover {
  background: #111727;
  color: #ffff !important;
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.eyepasswordsignup {
  position: absolute;
  padding: 35px;
  right: 33%;
  color:  #D0CFCF;
  font-size: 18px !important;
}
.eyepasswordsignup :hover {
  right: 33%;
  color: #FAB818; 
  font-size: 18px !important;
}

.Read_Policy {
  margin-right: 15%;
  font-size: 1vw;
}

.Read_Policy label {
      margin-left: 1%;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #FAB818 !important;
}


.signup i.rounded {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #929191;
  height: 43px;
}



.signup i.rounded :hover {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #FAB818;
  height: 43px;
}

.rounded {
  border: 1px solid;
  border-radius: 50% !important;
  padding: 1%;
}

.accountsign {
  color: #676666;
  font-family: 'museo_sans300';
}

.accountsign span {
  color: #000000;
  text-decoration: underline;
  font-weight: 900;
}

h6.social-login {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #676666;
}

.ccode{
  background: white;
  border: 1px solid #7a7777;
  height: 50px;
}

.ccodee{
width: 77%;
}
.ccodee .PhoneInputInput {
  height: 50px;
  padding: 2%;
}
.ccodee .PhoneInputCountry {
  height: 50px;
  border: 1px solid #7e7e7e;
  padding: 5%;
}

.ccodee.PhoneInput {
  margin: 0 auto;
}
.codenumber{
  width: 61%
}

.PhoneInputCountry {
  color: black; }


.signuperror{
  color: red;
  margin-left: 13%;
  margin-top: 1%;
  display: flex;
  font-family: 'museo_sans300';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* signup end */


/* model content  */

.agree-model{
  background-color: #000000;
}


.button-close{
  background-color: #FAB818;
  color: #ffff;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
 font-family: 'museo_sans700';
}
.button-close :hover{
  background-color: #FAB818 !important;
  color: #ffff !important;
}


.close{
  background: none;
    color: white;
    border: none;
}
/* model content  */



/* Mobile view  */

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 8%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 0;
    font-size: 1vw;
}
.Read_Policy label {
  font-size: 4vw;
}

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 21%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 12%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 3vw;
}

}

@media only screen and (min-width: 600px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 21%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 2%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 3vw;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 26%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 20%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 2vw;
}
}


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 29%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 5%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 2vw;
}

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 33%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 8%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 1.5vw;
}
}


@media screen and (min-width: 1400px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 36%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 0px;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 1.5vw;
}
}
@media screen and (min-width: 1600px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 38%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 21%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 1vw;
}
}
@media screen and (min-width: 1900px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 35px;
    right: 38%;
    font-size: 18px !important;
  }
  .Read_Policy {
    margin-right: 13%;
    font-size: 1vw;
}.Read_Policy label {
  font-size: 1vw;
}
}