
.checkBoxContainer {
    max-width: 215px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.checkBoxText{
    width: 160px;
    color: #000000;
    font-size: 14px;
    font-family: "museo_sans300" !important;
}