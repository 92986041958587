.order_home li {
    list-style:none !important;
}

.order_home ol, ul {
    padding-left: 0;
}

li:first-child {
    list-style:none;
}


.data {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.data_th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    height: 100px;
}