.adminSideBarLeft {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 990;
    height: 100vh;
    width: 275px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.adminSideBarRight {
    margin-left: auto;
    width: calc(100% - 275px);
}

.adminSideBarLeft::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #424242;
}

::-webkit-scrollbar {
    width: 6px;
}

@media screen and (max-width:991px) {
    .adminSideBarRight {
        width: 100%;
    }

    .adminSideBarLeft {
        left: -100%;
        transition: left 0.4s linear;
        width: 100%;
    }

    .adminSideBarLeft.active {
        left: 0;
    }
}