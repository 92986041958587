.theme_image {
    border: 2px solid gainsboro;
    border-radius: 15px;
    padding: 10px;
    margin: 15px;
}

.orderTitle {
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
}

.orderTitle.active::before {
    position: absolute;
    bottom: -8px;
    width: 50%;
    height: 6px;
    content: "";
    left: 0;
    right: 0;
    border-radius: 10px;
    margin: auto;
}