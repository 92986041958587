.editvideo #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.editvideo {
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}