.userAnalyticsBox {
    width: 200px !important;
}

.userDateTime {
    font-size: 11px;
    line-height: 25px
}

.totalResult {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
}

.countUsers {
    font-size: 23px;
    line-height: 40px;
    font-weight: 500;
}