
/* Card */
.card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .card-header,
  .card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
  }
  
  .user-webnexs .card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .user-webnexs span.small.pt-1.fw-bold {
    color: black;
}
  
  .card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
  }
  
  .card-body {
    padding: 0 20px 20px 20px;
  }
  
  .card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }
  
  .dashboard .nav-link{
    border-radius: 5px;
    border-bottom: 1px solid #e9e6e6;
    font-weight: 500;
    color: #000000;
    background-color: #e1e1e1;
  }

  .dashboard .nav-link.active {
    background: rgb(255 255 255 / 20%);
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

  .upload-video {background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;}

  .iq-card {
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    border: none;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;}

    
    .iq-cards
    {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 30px;
      border: none;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
  
    }
    .days-cards
    {
      background: #fff;
      border-radius: 5px;
      border: none;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .progress-bar-vertical {
      position: relative;
      display: inline-block;
      height: 70px;
      width: 195px;
      background: linear-gradient(to right, #0d6efd 99.9%, transparent 99.9%), radial-gradient(circle at 50% 50%, #0d6efd 25%, transparent 30%);
      background-position: 0% 50%, 0% 50%;
      background-size: 100% 2px, 50px 15px;
      background-repeat: no-repeat, repeat-x;
    }
    
    .progress-now-vertical {
      position: absolute;
      height: 100%;
      background: linear-gradient(to right, #0d6efd 99.9%, transparent 99.9%), radial-gradient(circle at 50% 50%, #0d6efd 25%, transparent 30%);
      
      
      background-position: 0% 50%, 0% 50%;
      background-size: 100% 5px, 50px 50px;
      background-repeat: no-repeat, repeat-x;
      z-index: 1;
    }
    
    .progress-now-vertical.right {
      right: 0px;
      background-position: 100% 50%, 100% 50%;
    }
    .section-con
    {
      height: 60vh;
    }