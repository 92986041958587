.t-1{
    font-size: 12px;
}
.imagedrop{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    cursor: pointer;
}
textarea#description {
    height: 120px;
}