body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 240, 240)
}


/* .dark {
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  }
  .light {
    background: #fff;
    color: rgb(0, 0, 0);
  } */