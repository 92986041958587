.dashboard .nav-link {
    border-radius: 5px;
    border-bottom: 1px solid #e9e6e6;
    color: #000000;
    background-color: #e1e1e1;
     width: 15rem;
    padding: 15px 10px;
}

.add {
    padding: 0.5rem 0.5rem;
    background-color: #198754!important;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.remove_url{
    background-color: #dc3545!important;
}
.tab-content{
    /* padding-top: 15px; */
    width: 100%;
}
.p1{
    font-size: 12px!important;
}
.fonti i{
 font-size: 45px;
 background-color: #e0e0e0;
 border-radius: 10px;
 padding: 5px;
}

.site-setting {
    color: #000000;
}
.site-setting:hover{
    color: #000000;
}

.sitecard{
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    height: 100%;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}