.contentname{
  border-radius: 10px;
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.content-setting {
  color: #000000;
}
.content-setting:hover {
  color: #000000;
}

.libraries .modal-dialog {
  max-width: 60%;
  margin: 3.00rem auto;
}