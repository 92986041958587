/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 26, 2022 */



@font-face {
    font-family: 'museo_sans700';
    src: url('museosans_700-webfont.woff2') format('woff2'),
         url('museosans_700-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans100';
    src: url('museosans-100-webfont.woff2') format('woff2'),
         url('museosans-100-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans300';
    src: url('museosans-300-webfont.woff2') format('woff2'),
         url('museosans-300-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans500';
    src: url('museosans_500-webfont.woff2') format('woff2'),
         url('museosans_500-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}