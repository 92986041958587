.pay-search
{
    background: rgba(250, 250, 250, 1);
    border: 1px solid transparent;
    height: 45px;
    font-size: 14px;
    width: 23%;
    -webkit-border-radius: 6px;
     border-radius: 4px;
    padding-left: 10px;
    color: #646464!important;
}
.pay-search:focus-visible
{
    outline: none;
}