.eyepasswordemail {
  position: absolute;
  padding: 33px;
  right: 0%;
  font-size: 18px !important;
  top: 75%;
}

.lockIcon,
.eyeOpen,
.eyeClosed {
  font-size: 23px;
}

.lockIcon {
  top: 15px;
  left: 10px;
}

.passWordIcon {
  top: 15px;
  right: 10px;
}

.serialNumber {
  width: 40px !important;
}

.redColorEmail {
  font-size: 13px;
  line-height: 23px;
}