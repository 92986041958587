.CPP_ANALYTICS .nav-link.active {
  background-color: #0d6efd;
  padding: 10px 60px;
  color: #ffff;
}

.CPP_ANALYTICS .nav-link {
  background-color: transparent;
  padding: 10px 60px;
  color: #000000;
  border-color: #0d6efd;
  border-radius: 5px;
}

.CPP_ANALYTICS .nav-tabs .nav-link:hover {
  border-color: #0d6efd;
  isolation: isolate;
}

.user_reg_analytics_count {
  font-size: 18px !important;
}
