@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --admin-button-color: #0d6efd;
}

body {
  font-family: "Poppins" !important;
  background-color: #f0f0f0 !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #424242;
}

button,
p,
input,
label,
span {
  font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

a {
  text-decoration: none !important;
}

/* Style for the dropdown container */
.editdropdown {
  position: relative;
  display: inline-block;
  z-index: 9;
}

/* Style for the dropdown button */
.editdropdown-button {
  /* background-color:  rgb(240, 240, 240) ; */
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown menu */
.editdropdown-menu {
  display: none;
  left: -163px;
  top: 0;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 185px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show the dropdown menu when the button is clicked */
.editdropdown:hover .editdropdown-menu {
  display: block;
}

/* Style for dropdown menu items */
.editdropdown-menu a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.mandatory {
  color: red;
}

.mandatorySimple::after {
  content: "*";
  color: red;
}

.jodit_sticky>.jodit-toolbar__box {
  position: inherit !important;
}

/* Highlight dropdown menu items on hover */
/* Style for the dropdown container */
.editdropdownpage {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown button */
.editdropdownpage-button {
  /* background-color:  rgb(240, 240, 240) ; */
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown menu */
.editdropdownpage-menu {
  display: none;
  left: -93px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show the dropdown menu when the button is clicked */
.editdropdownpage:hover .editdropdownpage-menu {
  display: block;
}

/* Style for dropdown menu items */
.editdropdownpage-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.mandatory {
  color: red;
}

/* Highlight dropdown menu items on hover */
.editdropdownpage-menu a:hover {
  background-color: #f1f1f1;
}

.PUBLISHED_active {
  background: #d3ffd3;
  color: green;
  font-weight: 600;
  font-size: 13px;
  border-radius: 20px;
  width: 120px;
}

.UNPUBLISHED_active {
  background: #ffd9d9;
  color: red;
  font-weight: 600;
  font-size: 13px;
  border-radius: 20px;
  width: 120px;
}

.DISABLED_active {
  background: #ffea008a;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border-radius: 20px;
  width: 120px;
}


.dashboard a {
  text-decoration: none;
  font-size: 16px;
}

.dashboard p {
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #161616;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #646464;
}

.liveAlert.alert.alert-success.alert-dismissible.fade.show {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

.liveAlert.alert.alert-danger.alert-dismissible.fade.show {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

.banner-slider .modal {
  top: 50px;
}

.delete_confirm {
  margin-top: 10rem;
}

.success-message i {
  font-size: 50px;
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid greenyellow;
  color: greenyellow;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

select {
  height: 50px;
}

.css-1xc3v61-indicatorContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0deg 3.96% 4.8% / 70%) !important;
  padding: 0px !important;
  box-sizing: border-box;
}

.css-15lsz6c-indicatorContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0deg 3.96% 4.8% / 70%) !important;
  /* padding: 0px !important; */
  box-sizing: border-box;
}

.css-t3ipsp-control {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0deg 3.96% 4.8% / 70%) !important;
  padding: 0px !important;
  box-sizing: border-box;
}

.css-1xc3v61-indicatorContainer svg {
  width: 18px !important;
  height: 18px !important;
}

.css-15lsz6c-indicatorContainer svg {
  width: 18px !important;
  height: 18px !important;
}

.css-15lsz6c-indicatorContainer svg.css-tj5bde-Svg {
  position: absolute;
  right: 10px;
  top: 11px;
}

.css-b62m3t-container {
  /* height: 50px; */
  border: 1px solid #e5e5ea !important;
  border-radius: 5px;
  align-items: center;
  padding: 5px 0 5px 0;
}

.thumbnails-images {
  width: auto;
  height: 100px;
}

.thumbnails-upload-images {
  width: 100%;
  height: 100px;
}

#publish_time {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: transparent !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #e5e5ea !important;
  height: 50px;
}

/* overlay.css */
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-icon {
  font-size: 25px;
  color: white;
}

.css-1xc3v61-indicatorContainer svg.css-tj5bde-Svg {
  position: absolute;
  right: 10px;
  top: 11px;
}

.css-1fdsijx-ValueContainer {
  padding: auto !important;
}

.css-15lsz6c-indicatorContainer svg.css-tj5bde-Svg path[d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"] {
  display: none;
}

.css-1xc3v61-indicatorContainer svg.css-tj5bde-Svg path[d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"] {
  display: none;
}

.errorred {
  color: red;
}

th {
  text-align-last: start !important;
  padding: 0;
}

td {
  text-align-last: start !important;
  padding: 0;
}

/* New Header siderbar 28/8/2023 */
/* New Header siderbar 28/8/2023 */
/* Mobile view  */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .footerlink {
    font-size: 15px;
    padding: 0;
    margin: 0;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .footerlink {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .footerlink {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .footerlink {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {}

/* old codes */

.errorred {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.errorredspan {
  position: absolute;
  bottom: -18px;
}

.imageForm {
  display: block;
  position: absolute;
  right: 16px;
  z-index: 1;
  bottom: 11px;
  width: 80px;
  height: 37px;
  object-fit: cover;
}

#nestable>table {
  overflow-x: auto;
}

.approveTable>table {
  overflow-x: auto;
}

#videoPlayer {
  width: 100%;
  height: 100%;
}

.editVideoPlayer {
  width: 125px !important;
  height: 75px;
}

.status {
  width: 80%;
  font-weight: bold;
  margin: 0 auto;
  border-radius: 4px;
}

.closeBtn {
  position: absolute !important;
  right: 0;
  top: 0px;
  width: 40px;
  background: transparent;
  z-index: 100 !important;
  height: 60px;
}

#PendingvideoPlayer {
  width: 100%;
  height: 80px;
}

.exportIcon {
  width: 22px;
  height: 22px;
}

input[type="checkbox"]:checked+label::before {
  background-color: #00f;
  border-color: #00f;
}

.idClass {
  width: 80px !important;
}

.page-link {
  transition: none !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.view-search {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  padding-left: 10px;
  color: #646464 !important;
}

.filterContainer {
  max-width: 270px;
  width: 100%;
  position: relative;
}

.video-overall-progress-li li {
  list-style-type: none !important;
}

.filter:focus {
  box-shadow: none !important;
}

select#rating {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#age_restrict {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#trailer_type {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#access {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#url_type {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#parent_id {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#artist_type {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

select#role_id {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px !important;
}

li {
  list-style-type: none;
}

.form-control.filter {
  max-width: 270px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 11px 13px;
  padding: 8px 37px;
  background-image: url("./components/Images/search_lens.png");
  transition: none !important;
}

.totalText {
  /* font-family: "museo_sans700" !important; */
  font-family: "Poppins" !important;
  font-style: normal;
  color: #424242 !important;
  font-size: 20px;
}

.react-bootstrap-table th.sortable {
  position: relative;
}

.react-bs-table-no-data {
  text-align: center;
}

.commonActionPadding {
  padding: 12px 16px;
  cursor: pointer;
}

.modal.packageModel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9997;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgb(0 0 0 / 41%);
}

.packageModel .btn-close:focus {
  box-shadow: none;
}

.noRecord {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
}

.dropzone {
  background-position: left;
  background-size: 75% !important;
  background-repeat: no-repeat;
  justify-content: flex-end !important;
}

.editFormImage {
  max-width: 170px !important;
  height: 100px;
  object-fit: contain;
  display: block;
  margin: 8px 0px;
}

.videoPreview {
  width: 80%;
  margin-right: auto;
  height: 100%;
}

.Toastify__toast-body {
  font-family: "Poppins";
  font-weight: 500;
}

.admin-sub-title {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  word-wrap: break-word;
}

.admin-sub-title {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  word-wrap: break-word;
}

.bootstrapTable table>thead>tr {
  border: none;
}

.bootstrapTable table>thead>tr>th {
  border: none;
  font-size: 16px;
  font-family: "Poppins" !important;
  padding: 10px;
  width: 130px;
  font-weight: 500;
  position: relative;
}

.bootstrapTable table>tbody>tr>td {
  white-space: normal;
  word-break: break-all;
  border: none;
  font-size: 15px;
  font-family: "Poppins" !important;
  padding: 10px;
  width: 130px;
  vertical-align: middle;
}

.bootstrapTable.multi-delete-width table>thead>tr>th:nth-child(1) {
  width: 4%;
}

.bootstrapTable .video-width-table {
  width: 200px;
}

.bootstrapTable .page-item.disabled>.page-link {
  opacity: 0.5;
}

.videoWidth>th:nth-child(2) {
  width: 30%;
  position: relative;
}

.videoWidth>th:nth-child(2) span {
  position: absolute;
  right: 0;
}

.videoWidth>th {
  width: 100px;
}

.sortable::after {
  content: "\2193";
  color: #ccc;
  padding: 0px 9px;
  position: absolute;
  right: 12px;
  top: 3px;
  font-size: 18px;
}

.sortable::before {
  content: "\2191";
  color: #ccc;
  position: absolute;
  right: 12px;
  top: 3px;
  font-size: 18px;
}

th[aria-label="Audios sort asc"].sortable::before {
  color: #0d6efd;
}

th[aria-label="Audios sort desc"].sortable::after {
  color: #0d6efd;
}

th[aria-label="Access sort asc"].sortable::before {
  color: #0d6efd;
}

th[aria-label="Access sort desc"].sortable::after {
  color: #0d6efd;
}

th[aria-label="Videos sort asc"].sortable::before {
  color: #0d6efd;
}

th[aria-label="Videos sort desc"].sortable::after {
  color: #0d6efd;
}

th[aria-label="Upload by sort asc"].sortable::before {
  color: #0d6efd;
}

th[aria-label="Upload sort desc"].sortable::after {
  color: #fff;
}

.profile {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 3px;
}

.table-subheading {
  font-size: 12px;
}

.rightVideoTitle {
  width: 200px;
}

.sliderBtnText {
  font-size: 13px !important;
  font-family: "Poppins" !important;
}

.admin-button-text {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #fff;
}

.button-color {
  color: #fff !important;
}

.button-bg-color {
  background-color: var(--admin-button-color) !important;
}

.admin-input-title {
  font-size: 15px;
  font-weight: 600;
}

.svgIcon {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.75px;
}

.bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  bottom: 32px;
}

.bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-item>a {
  display: block;
}

#pageDropDown {
  transition: none !important;
}

#pageDropDown:focus {
  box-shadow: none !important;
}

.react-bootstrap-table-page-btns-ul {
  justify-content: end;
}

.clearBtn {
  top: 10px;
  position: absolute;
  right: 0;
  background: transparent;
}

.css-d0mviz {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: 0px !important;
}

.custom-close-btn {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.adminHeadContainer {
  min-height: calc(100vh - 120px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.6s;
  transition: all 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: all 0.4s;
}

input:checked+.slider:before {
  left: 26px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-placeholder::placeholder {
  font-family: "Poppins" !important;
}

.imagedropcopy {
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-style: dashed;
  border-color: #cbd5e1;
  cursor: pointer;
  height: 100px;
}

.imagedropcopyimage {
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-style: dashed;
  border-color: #cbd5e1;
  width: 100%;
  cursor: pointer;
}

.admin-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
}

.customDropWrapper {
  width: 100%;
  height: 250px;
  border: 2px dashed #ccc;
  position: relative;
}

.customDropWrapper input {
  opacity: 0;
}

.dropZoneText {
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
}

.rowCheckboxDrag {
  width: 4% !important;
}

.category-order-title {
  padding: 10px;
  font-size: 16px;
  white-space: normal;
  word-break: break-all;
  border: none;
}

.border-width {
  border-width: 2px !important;
}

.orderHeading {
  font-size: 16px;
  font-weight: bold;
  line-height: 37px;
  font-family: 'Poppins';
}

.orderContainer>div:not(:nth-child(2), :nth-child(1), :nth-child(4)) {
  width: 15%;
}

.orderContainer>div:nth-child(1) {
  width: 5%;
}

.orderContainer>div:nth-child(2) {
  width: 25%;
}

.orderContainer>div:nth-child(4) {
  width: 15%;
}

.reloadButton {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-input {
  font-size: 13px;
  padding: 15px 20px;
  border-radius: 7px;
  font-weight: 500;
  font-family: 'Poppins';
}

.copy-board-container {
  cursor: pointer;
  position: absolute;
  top: -1px;
  width: 55px;
  right: -1px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  height: 51px;
}

.copy-board-input {
  padding-right: 55px;
}


.copy-reply {
  top: -45px;
  right: 6px;
}


@media screen and (max-width: 423px) {
  .deleteBtn {
    width: 100%;
  }
}