.r1 {
    background: #F7F8FC;
    height: 60px;
}

.audioUploadIcon {
    width: 20px;
    height: 20px;
    font-weight: bold;
}



.audio-posisttion {
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 2%;
}