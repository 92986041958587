.activeTrack {
    border-width: 2px !important;
}

.layoutCheckbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}