.dropZoneAds {
    width: 100%;
    height: 300px;
}

.dotted {
    border-style: dotted;
}

.dropZoneUpload {
    top: 0;
    left: 0;
}

.dropText {
    inset: 0;
    margin: auto;
    width: 100px;
    height: 20px;
}