.tooltip-container {
  position: relative;
  display: inline-block;
}

.iq-bg-warning {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.iq-bg-warning .las {
  margin-right: 5px;
}

.tooltip-text {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  top: 25px;
  left: 0px;
  font-size: 12px;
}

.iq-bg-warning:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
