.search-rev {
  border: none;
  height: 25px;
}
.search-rev:focus-visible {
  outline: none;
}
.filter-btn {
  padding: 8px 50px;
  /* background-color: white; */
}
.filter-btn:active {
  background-color: #3791ed !important;
}
.filter-btn:hover {
  background-color: #3791ed !important;
}
.filter-btn:focus {
  background-color: #3791ed !important;
  outline: none;
}
.download-btn {
  padding: 8px;
}
.r1 > th {
  text-align: center;
}
.r1 > td {
  text-align: center;
}
.user_reg_analytics_count {
  font-size: 18px !important;
}
