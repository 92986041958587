.Videopartner .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #0d6efd;
    border-color: #007fff #007fff #fff;
}
.Videopartner .nav-link {
    color: #000000;
    text-decoration: none;
}
.Videopartner .nav-tabs {
    border-bottom: none;
}