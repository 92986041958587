.Label_settings .iq-user-box1 {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  min-width: 35px;
  text-align: center;
  background: #20c997 !important;
}
.Label_settings .iq-user-box2 {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  min-width: 35px;
  text-align: center;
  background: #24c0d9 !important;
}
.Label_settings .iq-user-box3 {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  min-width: 35px;
  text-align: center;
  background: hsl(39deg 67.42% 58.25%) !important;
}
.Label_settings .iq-user-box4 {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  min-width: 35px;
  text-align: center;
  background: #17a2b8 !important;
}
.Label_settings .iq-user-box5 {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  min-width: 35px;
  text-align: center;
  background: #6c757d !important;
}

span.bg-warning.video_active {
  padding: 10px;
}

.Label_settings .nav-link.active {
  /* font-size: 16px; */
  border-bottom: 2px solid #006aff !important;
  font-weight: 500;
  color: #fff !important;
}
.Label_settings .nav-link {
  border-bottom: transparent;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
  padding: 10px 60px;
}
.Label_settings .data_th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  height: 65px;
  cursor: default;
}
