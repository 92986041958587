.all_media_library .view {
  color: #000000;
}
.all_media_library .edit {
  color: #000000;
}
.all_media_library .delete {
  color: #000000;
}
.videoWidth-library > th:nth-child(2) {
  width: 260px;
}
.videoWidth-library > th {
  width: 100px;
}
.rightVideoTitle-library{
  width: 200px;
}
.slug_diable_icon{
  color: #000000 !important;
}

