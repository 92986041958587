.has-switch .switch-on label {
  background-color: #fff;
  color: #000;
}

.make-switch {
  z-index: 2;
}

.iq-card {
  padding: 15px;
}

.p1 {
  font-size: 12px;
}

.react-calendar {
  width: 100%;
}

.black {
  color: #000;
  background: #f2f5fa;
  padding: 20px 20px;
  border-radius: 0px 4px 4px 0px;
}

#categorytbl {
  width: 100%;
}

.black:hover {
  background: #fff;
  padding: 20px 20px;
  color: rgba(66, 149, 210, 1);
}

.code_editor {
  min-height: 300px;
}

.has-switch .switch-on label {
  background-color: #fff;
  color: #000;
}

.make-switch {
  z-index: 2;
}

.home {
  margin: 2px 10px;
}

.switch {
  margin: 0px 4px;
}

label {
  font-size: 18px;
}

a {
  color: #000;
}

.table thead th {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #161616;
  /* border-bottom: 2px solid var(--iq-body-bg); */
}

.table thead th {
  /* vertical-align: bottom; */
  border-bottom: 2px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  /* vertical-align: bottom; */
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  /* border: 1px solid var(--iq-body-bg); */
  padding: 10px;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  /* border-top: 1px solid #dee2e6; */
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: middle;
  /* border-top: 1px solid #dee2e6; */
}

.headingpop {
  display: table-caption;
}

.home_mobile .nav-link {
  background: rgb(255 255 255 / 20%);
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-color: rgb(255 255 255 / 20%);
  padding: 10px 85px 10px 85px;
  border-color: rgb(255 255 255 / 20%);
}

.home_mobile .nav-link.active {
  background: rgb(255 255 255 / 20%);
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.home_mobile .nav-link.active {
  border-bottom: 1px solid #e9e6e6;
  font-weight: 500;
  color: #000000;

  border-bottom: 2px solid #006aff !important;
  /* background-color: #e1e1e1; */
  padding: 10px 60px 10px 60px;
  border-color: rgb(255 255 255 / 20%);
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
}

.nav-tabs {
  border-bottom: none;
}