.scroll-active .react-bootstrap-table {
    overflow-x: auto;
    width: 100%;
}

.filterTypeCustom {
    padding: 12px 17px;
    font-size: 13px;
    font-weight: 500;
    height: 42px;
    width: 130px;
    appearance: none;
}
.filterTypeCustom.calender{
    width: 150px;
}

.filterIcon {
    top: 14px;
    right: 12px;
}