.tabHeading{
    font-family: "museo_sans500" !important;
    font-style: normal;
    font-size: 16px !important;
    color: #424242 !important;
    background-color: transparent;
    position: relative;
    display: block;
}
.tabHeading.active{
font-family: "museo_sans700" !important ;
}
.tabHeading.active::after{
    content: "";
    position: absolute;
    width: 100%;
    bottom: -9px;
    left: 0;
    border-radius: 10px;
    height: 4px;
    background-color: rgb(77, 77, 247);

}